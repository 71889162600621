var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm work-card",
                  attrs: { title: "LBL0000215", noMarginPadding: true },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isWorking,
                                expression: "isWorking",
                              },
                            ],
                            attrs: { label: "LBL0000312", icon: "print" },
                            on: { btnClicked: _vm.printWorkPermit },
                          }),
                          _c("c-appr-btn", {
                            ref: "appr-btn",
                            attrs: {
                              name: "work-permit-appr-btn",
                              editable: _vm.editable,
                              approvalInfo: _vm.approvalInfo,
                            },
                            on: {
                              beforeApprAction: _vm.approvalWorkPermit,
                              callbackApprAction:
                                _vm.approvalWorkPermitCallback,
                              requestAfterAction: function ($event) {
                                return _vm.getDetail("save")
                              },
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  _vm.vendorRequestFlag,
                                expression:
                                  "editable && !disabled && vendorRequestFlag",
                              },
                            ],
                            attrs: {
                              label: "업체요청 반려",
                              icon: "front_hand",
                              color: "red-3",
                            },
                            on: { btnClicked: _vm.returnDialogOpen },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  !_vm.vendorRequestFlag,
                                expression:
                                  "editable && !disabled && !vendorRequestFlag",
                              },
                            ],
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.workPermit,
                              mappingType: _vm.mappingType,
                              label: _vm.saveBtnLabel,
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveWorkPermit,
                              btnCallback: _vm.saveWorkPermitCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  !_vm.disabled &&
                                  _vm.vendorRequestFlag &&
                                  _vm.isOld &&
                                  _vm.workPermit.swpStepCd !== "SS00000001",
                                expression:
                                  "editable && !disabled && vendorRequestFlag && isOld && workPermit.swpStepCd !== 'SS00000001'",
                              },
                            ],
                            attrs: {
                              isSubmit: _vm.isReqApproval,
                              url: _vm.reqApprovalUrl,
                              param: _vm.workPermit,
                              mappingType: "PUT",
                              label: "업체요청 승인",
                              icon: "check",
                            },
                            on: {
                              beforeAction: _vm.reqApprovalWorkPermit,
                              btnCallback: _vm.reqApprovalWorkPermitCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.isWriting &&
                                  _vm.isOld &&
                                  !_vm.popupParam.isApprContent &&
                                  !_vm.workPermit.sysApprovalRequestId &&
                                  !_vm.vendorRequestFlag,
                                expression:
                                  "editable && isWriting && isOld && !popupParam.isApprContent && !workPermit.sysApprovalRequestId && !vendorRequestFlag",
                              },
                            ],
                            attrs: { label: "LBL0000252", icon: "remove" },
                            on: { btnClicked: _vm.removeWorkPermit },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "card-detail" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              label: "LBL0000200",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.workPermit.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "plantCd", $$v)
                              },
                              expression: "workPermit.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              label: "LBL0000216",
                              name: "permitNo",
                            },
                            model: {
                              value: _vm.workPermit.permitNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "permitNo", $$v)
                              },
                              expression: "workPermit.permitNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              label: "LBL0000217",
                              name: "permitDate",
                            },
                            model: {
                              value: _vm.workPermit.permitDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "permitDate", $$v)
                              },
                              expression: "workPermit.permitDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              range: true,
                              minuteStep: 10,
                              type: "time",
                              label: "LBL0000218",
                              name: "workTime",
                            },
                            model: {
                              value: _vm.workPermit.workTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.workPermit, "workTime", $$v)
                              },
                              expression: "workPermit.workTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 work-item",
                        },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              disabled: !_vm.isWriting,
                              editable: _vm.editable,
                              codeGroupCd: "SOP_SUPPLEMENT_WORK_TYPE_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "LBL0000205",
                              name: "supWorks",
                            },
                            on: { datachange: _vm.changeSupWork },
                            model: {
                              value: _vm.supWorks,
                              callback: function ($$v) {
                                _vm.supWorks = $$v
                              },
                              expression: "supWorks",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isWorkInfo
                        ? [
                            _c("q-slide-transition", [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.visible,
                                      expression: "visible",
                                    },
                                  ],
                                  staticClass: "row",
                                  staticStyle: {
                                    display: "-webkit-inline-box",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-6 col-md-3 col-lg-3",
                                    },
                                    [
                                      _c(
                                        "q-card",
                                        {
                                          staticClass: "work-card",
                                          class: _vm.cancelFlag
                                            ? "bg-highLight"
                                            : "",
                                          attrs: { flat: "", bordered: "" },
                                        },
                                        [
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass:
                                                "q-pa-xs q-pl-sm bg-grey-3",
                                              class:
                                                this.editable &&
                                                !this.disabled &&
                                                !this.disableCancel
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed",
                                              on: {
                                                click: _vm.qCardCancelClick,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-orange-9 work-title-text",
                                                },
                                                [
                                                  _c("q-checkbox", {
                                                    attrs: {
                                                      size: "22px",
                                                      label: "",
                                                      disable:
                                                        _vm.disableCancel ||
                                                        _vm.disabled ||
                                                        !_vm.editable,
                                                    },
                                                    on: {
                                                      input: _vm.changeCancel,
                                                    },
                                                    model: {
                                                      value: _vm.cancelFlag,
                                                      callback: function ($$v) {
                                                        _vm.cancelFlag = $$v
                                                      },
                                                      expression: "cancelFlag",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$comm.getLangLabel(
                                                          "LBL0000305",
                                                          "SWP"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("q-separator"),
                                          _c(
                                            "q-card-section",
                                            { staticClass: "no-padding row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 work-item",
                                                },
                                                [
                                                  _c("c-textarea", {
                                                    attrs: {
                                                      required: _vm.cancelFlag,
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.cancelFlag,
                                                      rows: 3,
                                                      label: "LBL0000308",
                                                      beforeText: "",
                                                      name: "cancelRemark",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .cancelRemark,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "cancelRemark",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.cancelRemark",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-6 col-md-3 col-lg-3",
                                    },
                                    [
                                      _c(
                                        "q-card",
                                        {
                                          staticClass: "work-card",
                                          class: _vm.overFlag
                                            ? "bg-highLight"
                                            : "",
                                          attrs: { flat: "", bordered: "" },
                                        },
                                        [
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass:
                                                "q-pa-xs q-pl-sm cursor-pointer bg-grey-3",
                                              staticStyle: {
                                                position: "relative",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "3px",
                                                    "z-index": "10600",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "q-btn-group",
                                                    { attrs: { outline: "" } },
                                                    [
                                                      _vm.editable &&
                                                      _vm.overFlag &&
                                                      !_vm.disabled
                                                        ? _c("c-btn", {
                                                            attrs: {
                                                              label:
                                                                "LBL0000253",
                                                              icon: "add",
                                                              showLoading: false,
                                                            },
                                                            on: {
                                                              btnClicked:
                                                                _vm.addOver,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm.editable &&
                                                      _vm.overFlag &&
                                                      !_vm.disabled
                                                        ? _c("c-btn", {
                                                            attrs: {
                                                              label:
                                                                "LBL0000275",
                                                              icon: "remove",
                                                              showLoading: false,
                                                            },
                                                            on: {
                                                              btnClicked:
                                                                _vm.removeOver,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass:
                                                "q-pa-xs q-pl-sm bg-grey-3",
                                              class:
                                                this.editable && !this.disabled
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed",
                                              staticStyle: {
                                                "min-height": "23px",
                                              },
                                              on: { click: _vm.qCardOverClick },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-orange-9 work-title-text float-left",
                                                  staticStyle: {
                                                    position: "absolute",
                                                    top: "-3px",
                                                  },
                                                },
                                                [
                                                  _c("q-checkbox", {
                                                    attrs: {
                                                      size: "22px",
                                                      label: "",
                                                      disable: _vm.disabled,
                                                    },
                                                    on: {
                                                      input: _vm.changeOver,
                                                    },
                                                    model: {
                                                      value: _vm.overFlag,
                                                      callback: function ($$v) {
                                                        _vm.overFlag = $$v
                                                      },
                                                      expression: "overFlag",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$comm.getLangLabel(
                                                          "LBL0000306",
                                                          "SWP"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("q-separator"),
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass: "no-padding row",
                                              staticStyle: { width: "100%" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("c-table", {
                                                    ref: "tableOver",
                                                    staticClass:
                                                      "work-permit-table",
                                                    attrs: {
                                                      title: "",
                                                      tableId: "01",
                                                      columns: _vm.grid.columns,
                                                      data: _vm.workPermit
                                                        .overs,
                                                      gridHeight:
                                                        _vm.grid.height,
                                                      editable:
                                                        _vm.editable &&
                                                        _vm.overFlag &&
                                                        !_vm.disabled,
                                                      tableTimePickerOptions:
                                                        _vm.tableTimePickerOptions,
                                                      filtering: false,
                                                      columnSetting: false,
                                                      usePaging: false,
                                                      isTitle: false,
                                                      hideHeader: false,
                                                      hideBottom: true,
                                                      noHighLight: true,
                                                      noHoverRow: true,
                                                      selection: "multiple",
                                                      rowKey: "sopOverResultId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 grid-style-transition",
                                    },
                                    [
                                      _c(
                                        "q-card",
                                        {
                                          staticClass: "work-card",
                                          class: _vm.completeFlag
                                            ? "bg-highLight"
                                            : "",
                                          attrs: { flat: "", bordered: "" },
                                        },
                                        [
                                          _c(
                                            "q-card-section",
                                            {
                                              staticClass:
                                                "q-pa-xs q-pl-sm bg-grey-3",
                                              class:
                                                this.editable &&
                                                !this.disabled &&
                                                !this.disableComplete
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed",
                                              on: {
                                                click: _vm.qCardCompleteClick,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-orange-9 work-title-text",
                                                },
                                                [
                                                  _c("q-checkbox", {
                                                    attrs: {
                                                      size: "22px",
                                                      label: "",
                                                      disable:
                                                        _vm.disableComplete ||
                                                        _vm.disabled,
                                                    },
                                                    on: {
                                                      input: _vm.changeComplete,
                                                    },
                                                    model: {
                                                      value: _vm.completeFlag,
                                                      callback: function ($$v) {
                                                        _vm.completeFlag = $$v
                                                      },
                                                      expression:
                                                        "completeFlag",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$comm.getLangLabel(
                                                          "LBL0000307",
                                                          "SWP"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c("q-separator"),
                                          _c(
                                            "q-card-section",
                                            { staticClass: "no-padding row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-datepicker", {
                                                    attrs: {
                                                      required:
                                                        _vm.completeFlag,
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      "time-picker-options":
                                                        _vm.overTimeOption,
                                                      minuteStep: 10,
                                                      type: "time",
                                                      label: "LBL0000309",
                                                      beforeText: "",
                                                      name: "workCompleteTime",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-field", {
                                                    attrs: {
                                                      required:
                                                        _vm.completeFlag,
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      data: _vm.workPermit,
                                                      deptValue:
                                                        "workCompleteEntryDeptCd",
                                                      type: "dept_user",
                                                      label: "LBL0000240",
                                                      beforeText: "",
                                                      name: "workCompleteEntryUserId",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteEntryUserId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteEntryUserId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteEntryUserId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-4 work-item",
                                                },
                                                [
                                                  _c("c-field", {
                                                    attrs: {
                                                      required:
                                                        _vm.completeFlag,
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      data: _vm.workPermit,
                                                      deptValue:
                                                        "workCompleteWorkDeptCd",
                                                      type: "dept_user",
                                                      label: "LBL0000256",
                                                      beforeText: "",
                                                      name: "workCompleteWorkUserId",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .workCompleteWorkUserId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "workCompleteWorkUserId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.workCompleteWorkUserId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 work-item",
                                                },
                                                [
                                                  _c("c-text", {
                                                    attrs: {
                                                      editable: _vm.editable,
                                                      disabled:
                                                        _vm.disabled ||
                                                        !_vm.completeFlag,
                                                      label: "LBL0000311",
                                                      beforeText: "",
                                                      name: "actionRemark",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.workPermit
                                                          .actionRemark,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.workPermit,
                                                          "actionRemark",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "workPermit.actionRemark",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-12 folder-area" },
                              [
                                _c("q-btn", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "2px",
                                    color: "light-blue",
                                    icon: _vm.folderIcon,
                                  },
                                  on: { click: _vm.folder },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-tab", {
        staticClass: "q-mt-sm",
        attrs: {
          type: "tabcard",
          align: "left",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          dense: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.componentKey,
                  ref: "swp-tab",
                  tag: "component",
                  attrs: {
                    workPermit: _vm.workPermit,
                    supWork: tab.supWork,
                    isOld: _vm.isOld,
                    isWriting: _vm.isWriting,
                    saveAction: _vm.saveAction,
                    height: _vm.tabHeight,
                  },
                  on: {
                    "update:workPermit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:work-permit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:supWork": function ($event) {
                      return _vm.$set(tab, "supWork", $event)
                    },
                    "update:sup-work": function ($event) {
                      return _vm.$set(tab, "supWork", $event)
                    },
                    validResult: _vm.validResult,
                  },
                }),
              ]
            },
          },
          {
            key: "customTab",
            fn: function () {
              return [
                _c("q-tab", {
                  attrs: {
                    name: "references",
                    icon: "description",
                    label: _vm.$comm.getLangLabel("LBL0000220", "SWP"),
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "customTabPanel",
            fn: function () {
              return [
                _c(
                  "q-tab-panel",
                  { attrs: { name: "references" } },
                  [
                    _c(
                      "keep-alive",
                      [
                        _c(_vm.referencesCompo, {
                          key: _vm.referencesKey,
                          tag: "component",
                          attrs: {
                            workPermit: _vm.workPermit,
                            supWorks: _vm.supWorks,
                            saveCall: _vm.saveCall,
                            isWriting: _vm.isWriting,
                            height: _vm.tabHeight,
                          },
                          on: {
                            "update:workPermit": function ($event) {
                              _vm.workPermit = $event
                            },
                            "update:work-permit": function ($event) {
                              _vm.workPermit = $event
                            },
                            "update:supWorks": function ($event) {
                              _vm.supWorks = $event
                            },
                            "update:sup-works": function ($event) {
                              _vm.supWorks = $event
                            },
                            "update:saveCall": function ($event) {
                              _vm.saveCall = $event
                            },
                            "update:save-call": function ($event) {
                              _vm.saveCall = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.returnDialog.show,
            callback: function ($$v) {
              _vm.$set(_vm.returnDialog, "show", $$v)
            },
            expression: "returnDialog.show",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "450px" } },
            [
              _c(
                "q-form",
                { ref: "editForm2" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "반려 사유" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.editable && !_vm.disabled,
                                    expression: "editable && !disabled",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isReturn,
                                  url: _vm.saveUrl,
                                  param: _vm.workPermit,
                                  mappingType: "PUT",
                                  label: "반려",
                                  icon: "front_hand",
                                  color: "red-3",
                                },
                                on: {
                                  beforeAction: _vm.returnWorkPermit,
                                  btnCallback: _vm.returnWorkPermitCallback,
                                },
                              }),
                              _c("c-btn", {
                                attrs: { label: "닫기", icon: "cancel" },
                                on: { btnClicked: _vm.closeDialog },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.disabled,
                                rows: 5,
                                label: "",
                                name: "returnRemark",
                              },
                              model: {
                                value: _vm.workPermit.returnRemark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.workPermit, "returnRemark", $$v)
                                },
                                expression: "workPermit.returnRemark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }